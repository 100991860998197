<template>
  <div >
    <el-row>
      <el-col :offset="17" class="ignore-button" :span="7">
        <el-button-group>
          <el-tooltip
              class="ignore-button"
              effect="dark"
              content="Xuất excel về máy"
              placement="top-start"
          >
            <el-button
                type="success"
                @click="handleDownloadExcel"
                icon="el-icon-download"
            ></el-button>
          </el-tooltip>
          <el-tooltip
              class="ignore-button"
              effect="dark"
              content="In pdf"
              placement="top-start"
          >
            <el-button
                type="success"
                @click="handlePrint"
                icon="el-icon-files"
            ></el-button>
          </el-tooltip>
          <el-tooltip
              effect="dark"
              content="Cài đặt"
              placement="top-start"
          >
            <el-button
                type="success"
                @click="settingFontSize"
                icon="el-icon-setting"
            ></el-button>
          </el-tooltip>
        </el-button-group>
      </el-col>
    </el-row>

    <div v-for="(data, index) in dataBillPreview" :key="index" class="pdf_container" :style="customStyle">
      <br/>
      <div style="width: 96%; margin: auto">
        <el-row class="header_kids" v-if="data.logoSchool !== null">
          <el-col :span="6">
            <img id="logo_onecam"  :src="data.logoSchool" alt=""/>
          </el-col>
          <el-col :span="12" style="text-align: center; line-height: 1.5">
            <p>
              <b style="">{{ data.nameSchool }}</b>
              <br/>
              <span>{{ data.addressSchool }} </span>

              <br/>
              <b>PHIẾU THANH TOÁN LƯƠNG THÁNG {{ dataSearch.month }}/{{dataSearch.year}} - ĐỢT {{ dataSearch.spendTurn }}</b>
            </p>
          </el-col>
        </el-row>
        <el-row class="header_kids" v-else>
          <el-col :offset="6" :span="12" style="text-align: center; line-height: 1.5">
            <p>
              <b style="">{{ data.nameSchool }}</b>
              <br />
              <span>{{ data.addressSchool }} </span>

              <br />
              <b>PHIẾU THANH TOÁN LƯƠNG THÁNG {{ dataSearch.month }}/{{dataSearch.year}} - ĐỢT {{ dataSearch.spendTurn }}</b>
            </p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" class="print__left"
          ><span><b>Họ tên:</b> {{ data.fullName }} ({{data.code}})</span></el-col
          >
          <el-col :span="12" class="print__right"
          ><span><b>Mã:</b> ..........</span></el-col
          >
          <el-col :span="12" class="print__left"
          ><span><b>Phòng ban:</b> {{ data.nameDepartment }}</span></el-col
          >
          <el-col :span="12" class="print__right"
          ><span><b>Số:</b> ..........</span></el-col
          >
          <el-col :span="12" class="print__left"
          ><span><b>SĐT:</b> {{ data.phoneInfo }}</span></el-col
          >
          <el-col :span="12" class="print__right"
          ><span><b>Quyển số:</b> ..........</span></el-col
          >
        </el-row>

        <el-row style="margin-top: 6px">
          <print-null-table v-if="configData.statusPrint!==true" :table-data="data"/>
          <print-not-null-table v-else :table-data="data"/>
        </el-row>
        <el-row  style="margin-top: 5px">
          <span><b>Ghi chú hóa đơn:</b> <span style="white-space: pre-line;font-style: italic;">{{ data.noteExcel2 }}</span></span>
        </el-row>
        <el-row style="margin-top: 5px">
          <el-col class="print__left" span="8">
            <span><b>STK:</b> {{ data.accountNumber }}</span>
          </el-col>
          <el-col span="8">
            <span><b>Chủ TK:</b> {{ data.fullName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <span><b>Ngân hàng:</b> {{ data.bankName }}</span>
        </el-row>

        <el-row   class="footer_field">
          <el-col v-if="configData.statusShowDateTime" :offset="6">
            <span><i>{{currentTime}}, ngày {{day}} tháng {{month}} năm {{year}}</i></span>
          </el-col>
          <el-col :span="12"><b>Người nộp tiền</b></el-col>
          <el-col :span="12"><b>Người lập phiếu</b></el-col>
          <el-col  :offset="12" :span="12" style="margin-top: 70px"><b>{{data.userName}}</b></el-col>
        </el-row>

        <!-- Kiểm tra nếu là trang cuối cùng ( tránh in thêm trang blank ) -->
        <div
            :class="{
              'page-break': true,
              'last-page-break': index === dataBillPreview.length - 1,
            }"
        ></div>
      </div>
    </div>
    <div>
      <el-dialog
          title="Thiết lập cỡ chữ"
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          width="300px"
      >
        <el-row>
          <el-col :span="8" style="margin-top: 8px">
            <span>Chọn cỡ chữ</span>
          </el-col>
          <el-col :span="4">
            <el-select v-model="customFontSize" placeholder="Chọn cỡ chữ" style="width: 70px" size="smail">
              <el-option
                  v-for="item in listFontSize"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="dialogVisible = false">Đóng</el-button>
         </span>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import {mapState} from "vuex";
import {v4} from "uuid";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";
import SchoolConfigService from "@/services/SchoolService/SchoolConfigService";
import PrintNotNullTable from "@/components/FinanceVer2/employeeSalary/printPayment/PrintNotNullTable.vue";
import PrintNullTable from "@/components/FinanceVer2/employeeSalary/printPayment/PrintNullTable.vue";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  components: {PrintNullTable, PrintNotNullTable},
  data() {
    return {
      listBillParams: {
        date: "",
        idInfoList: "",
        spendTurn: "",
        guiId: "",
      },
      dataBillFirst: [],
      currentTime: '',
      day: '',
      month: '',
      year: '',
      customFontSize: 15,
      dialogVisible: false,
      listFontSize: [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,29,29,30],
      configData: {
        statusPrint: undefined, // true = cho phép in khoản chưa có tiền
        statusShowDateTime: undefined,
      },
    };
  },
  created() {
    // Lấy ngày hiện tại
    let now = new Date();
    // Lấy giờ-phút hiện tại
    let hours = now.getHours();
    let minutes = now.getMinutes();
    this.currentTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

// Lấy ngày-tháng-năm hiện tại
    let dayNow = now.getDate();
    this.day = `${dayNow < 10 ? '0' + dayNow : dayNow}`
    let monthNow = now.getMonth() + 1; // Tháng bắt đầu từ 0
    this.month = `${monthNow < 3 ? '0' + monthNow : monthNow}`
    this.year = now.getFullYear();
    SchoolConfigService.getConfigPrintOrder().then((res) => {
      if (res && res.data) {
        this.configData.statusPrint = res.data.statusPrint;
        this.configData.statusShowDateTime = res.data.statusShowDateTime;
      }
    })
    // cái này xử lí nếu bấm nút f5
    if (this.dataBillPreview.length === 0 && Object.keys(this.dataSearch).length === 0) {
      this.$router.push('/finance/employee/salary/new')
    }
  },
  computed: {
    ...mapState("employeeSalaryNew", [
      "dataBillPreview",
      "dataSearch",
      "idInfoList",
    ]),
    customStyle() {
      return {
        '--custom-font-size': this.customFontSize + 'px'
      };
    }
  },
  // xoá dữ liệu khi back lại, f5 trang
  beforeRouteLeave(to, from, next) {
    this.$store.commit("employeeSalaryNew/renew_bill_preview");
    next(); // Xử lí tiếp tục điều hướng
  },
  updated() {

  },
  methods: {
    handlePrint() {
      window.print()
    },
    settingFontSize(){
      this.dialogVisible = true
    },
    handleDownloadExcel() {
      const guiId = v4();
      this.listBillParams.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month);
      this.listBillParams.idInfoList = this.idInfoList;
      this.listBillParams.spendTurn = this.dataSearch.spendTurn;
      this.listBillParams.guiId = guiId;
      // bắt đầu gọi export
      NewSalaryService
          .exportBillToExcel(this.listBillParams)
          .then((res) => {
            // chạy neeus k conf processing
            if (res.data.message === "processing") {
              this.$notify.info({
                title: "Thông báo",
                message: "Dữ liệu đang được xử lý !",
              });
            }
            // Call the function again after 5 seconds
            const checkProcess = setInterval(() => {
              NewSalaryService
                  .exportBillToExcel(this.listBillParams)
                  .then((res) => {
                    if (res.data.message === "fail") {
                      clearInterval(checkProcess)
                      this.$notify.error({
                        title: "Thông báo",
                        message: "Lỗi không xuất được data !",
                      });
                      return;
                    }
                    // chạy neeus k conf processing
                    if (res.data.message === "processing") {
                      this.$notify.info({
                        title: "Thông báo",
                        message: "Dữ liệu đang được xử lý !",
                      });
                    } else {
                      this.$notify.success({
                        title: "Thông báo",
                        message: "Dữ liệu xuất thành công",
                      });
                      const excelFilename = res.data.message.split('complete_')[1]; // Provide a desired filename for the downloaded file
                      clearInterval(checkProcess);
                      return NewSalaryService
                          .downloadBillToExcel(this.listBillParams.guiId)
                          .then((res) => {
                            const excelData = res.data;
                            handleAndDownloadExcel(excelData, excelFilename);
                          });

                    }
                  })
                  .catch((err) => {
                    return Promise.reject(err);
                  });
            }, 3000);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
  },


};
</script>
<style scoped>
.footer_field {
  text-align: center;
}

#logo_onecam {
  margin-left: 6px;
  margin-top: 20px;
  float: left;
  object-fit: scale-down;
  width: 9vh;
  height: 8vh;
}

.header_kids {
  margin-top: 20px;
  height: 13%;
}

.pdf_container {
  outline: 1px solid transparent;
  margin: auto;
  background-color: white;
  font-family: "Times New Roman", serif;
  width: 40%;
  height: 100%;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  transition: transform 0.3s ease-in-out;
}



.print__left {
  white-space: nowrap;
  text-align: left;
}

.print__right {
  text-align: right;
}
@page {
  size: A4 portrait; /* Set the paper size to A4 in landscape mode */
  margin: 0;
}
@media print{
  :root {
    --custom-font-size: 15px;
  }
  .ignore-button {
    display: none !important;
  }

  .print__left {
    float: left;
  }

  .print__right {
    float: right;
  }

  .page-break {
    page-break-after: always;
  }

  /* Sẽ không tự động thêm trang trống nữa */
  .last-page-break {
    page-break-after: avoid;
  }

  .pdf_container {
    font-size: var(--custom-font-size);
    box-shadow: 0 0 #0000;
    margin-top: 0;
    width: 80%;
    border-color: red;
    border-width: 1px;
    page-break-inside: avoid;
    //height: 100%;
  }

  .header_kids {
    margin-top: 0;
    height: 13%;
  }

  .bold-text {
    font-weight: bold;
  }
  .el-row {
    page-break-inside: avoid;
  }
}
</style>